a{
    color: var(--#{$variable-prefix}primary);
}
a:hover{
    color: var(--#{$variable-prefix}primary);
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
    color: var(--#{$variable-prefix}primary-shade-80);
}

body{
    background-color: var(--bs-body-bg);
}