.form-check-input{
    border-color: var(--#{$variable-prefix}primary-shade-20);
    &:checked{
        border-color: var(--#{$variable-prefix}primary-shade-20);
        background-color: var(--#{$variable-prefix}primary) !important;
    }
    &:focus{
        border-color: var(--#{$variable-prefix}primary-shade-20) !important;
        background-color: var(--#{$variable-prefix}primary) !important;
    }
}



.border-primary{
    border-color: var(--#{$variable-prefix}primary) !important;
}
